//
//  Return a Locale Date Object from a UTC SQL datetime string
//  
export function SQLtoUTCDateObj (str, time = true) {
    if(str === null || str === '' || typeof str === 'undefined')
        return null;

    const d = str.split(/-|T|:| |\.|Z/);
    for(var i=0;i<d.length;i++){
        d[i] = parseInt(d[i]);
    }
    if(typeof d[3] != 'undefined' && time === true){
        return new Date(Date.UTC(d[0], (d[1]-1), d[2], d[3], d[4], d[5]));
    }else{
        return new Date(Date.UTC(d[0], (d[1]-1), d[2], 0, 0, 0, 0));
    }
}

//
//  Return a Locale Date String from a UTC SQL datetime string
//  
export function SQLtoUTCDate (str, time = true) {
    const jD = SQLtoUTCDateObj(str);
    if(jD == null)
        return null;

    return time?jD.toLocaleDateString(navigator.language) + ' ' + jD.toLocaleTimeString(navigator.language):jD.toLocaleDateString(navigator.language);
}

//
//  Return a Locale ISO String from a UTC SQL datetime string
//  
export function SQLtoUTCISODateTime (str) {
    const jD = SQLtoUTCDateObj(str);
    if(jD == null)
        return '';

    return jD.toISOString()
}

//
//  Return a Locale ISO Date String from a UTC SQL datetime string
//  
export function SQLtoUTCISODate (str) {
    const jD = this.SQLtoUTCDateObj(str);
    if(jD == null)
        return '';

    return jD.toISOString().split('T')[0]
}

export function toLocaleDateTime (d) {
    const jD = new Date(d);

    return jD.toLocaleDateString('en-GB') + ' ' + jD.toLocaleTimeString('en-GB');
}

export function toDateTimeNoSecs(d) {
    const jD = new Date(d);
    return new Date(jD.getFullYear(), jD.getMonth(), jD.getDate(), jD.getHours(), jD.getMinutes());
}


export function SQLTimeSince (d) {
    return this.timeSince(SQLtoUTCDateObj(d));
} 

export function timeSince (d) {
    const seconds = (new Date().getTime() - d.getTime()) / 1000;

    // 60 secs in a min
    // 3600 secs in an hour
    // 86400 secs in a day
    // 172800 secs in two days

    if(seconds > 604800)
        return d.toLocaleDateString('en-GB');
    if(seconds > 172800)
        return Math.floor(seconds/86400) + ' days ago';
    if(seconds > 86400)
        return 'Yesterday';
    if(seconds > 3600)
        return Math.floor(seconds/3600) + 'h ago';
    if(seconds > 60)
        return Math.floor(seconds/60) + 'm ago';
    return Math.floor(seconds) + 's ago';
}

export function SQLTimeSinceSecs (d) {
    return this.timeSinceSecs(SQLtoUTCDateObj(d));
} 

export function timeSinceSecs (d) {
    return Math.floor((new Date().getTime() - d.getTime()) / 1000);
}

export function SQLTimeSinceHours (d) {
    return this.timeSinceHours(SQLtoUTCDateObj(d));
} 

export function timeSinceMinutes (d) {
    const secs = this.timeSinceSecs(d);
    const mins = secs / 60;
    return Math.floor(mins);
}

export function timeSinceHours (d) {
    const secs = this.timeSinceSecs(d);
    const hours = secs / 3600;
    return Math.floor(hours);
}

export function timeUntil (d) {
    const seconds = (d.getTime() - new Date().getTime()) / 1000;

    // 60 secs in a min
    // 3600 secs in an hour
    // 86400 secs in a day
    // 172800 secs in two days

    if(seconds > 604800)
        return d.toLocaleDateString('en-GB');
    if(seconds > 172800)
        return Math.floor(seconds/86400) + ' days';
    if(seconds > 3600)
        return Math.floor(seconds/3600) + ' hrs';
    if(seconds > 60)
        return Math.floor(seconds/60) + ' mins';
    return Math.floor(seconds) + ' secs';
}


/**
 * 
 * @param {Number} s The number of seconds you wish to convert to a nice looking string
 * @returns {String} e.g. '2d 13h 23m 38s'
 */
export function secsToTimeStr (s) {
    let seconds = s;
    let str = '';

    // 60 secs in a min
    // 3600 secs in an hour
    // 86400 secs in a day
    // 172800 secs in two days

    // Days
    if(seconds > 86400){
        const days = Math.floor(seconds/86400);
        str += ` ${days}d`;
        seconds = seconds - (days * 86400);
    }

    // Hours
    if(seconds > 3600){
        const hours = Math.floor(seconds/3600);
        str += ` ${hours}h`;
        seconds = seconds - (hours * 3600);
    }

    // Minutes
    if(seconds > 60){
        const minutes = Math.floor(seconds/60);
        str += ` ${minutes}m`;
        seconds = seconds - (minutes * 60);
    }

    // Seconds
    if(seconds > 0)
        str += ` ${seconds}s`;

    if(str.length === 0)
        str = `0s`;


    return str.trim();
}